import { createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";

import Modal from "@/ui-lib/modal/Modal";
import Table from "@/ui-lib/table/Table";
import { OrganizationGameBought } from "@user/types/OrganizationGameBought";
import { convertISODateToDisplayFormatDateOnly } from "@/utils/convertISODateToDisplayFormat";

type Props = {
  organizationGamesBought: OrganizationGameBought[];
  onClose?: () => void;
};

const ModalOrganizationGamesBoughtByCompetition = ({
  organizationGamesBought,
  onClose,
}: Props) => {
  const { t } = useTranslation();

  const columlHelper = createColumnHelper<OrganizationGameBought>();

  const columns = [
    columlHelper.accessor("organization", {
      cell: (info) => (
        <>
          <p>{info.row.original.game.competition.name}</p>
        </>
      ),
      header: () =>
        t("admin.organization-competitions.organization-competition-name"),

      size: 30,
    }),
    columlHelper.accessor("game", {
      cell: (info) => (
        <div>
          <p>
            {convertISODateToDisplayFormatDateOnly(info.row.original.game.date)}
          </p>
        </div>
      ),
      header: () => t("admin.organization-competitions.date"),
      size: 20,
    }),
    columlHelper.accessor("game", {
      cell: (info) => (
        <div>
          <p>
            {info.row.original.game.team_away.name} VS{" "}
            {info.row.original.game.team_home.name}{" "}
          </p>
        </div>
      ),
      header: () => t("admin.organization-competitions.games"),
      size: 100,
    }),
  ];

  return (
    <Modal
      title={t("admin.organization-competitions.list-of-games")}
      onClose={onClose}
    >
      <div className="modal-organization-list-of-games">
        <Table
          columns={columns}
          data={organizationGamesBought ?? []}
          hasGlobalFilter={true}
          maxHeight="60vh"
        />
      </div>
    </Modal>
  );
};

export default ModalOrganizationGamesBoughtByCompetition;
